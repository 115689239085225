#portfolio {
    background: #201c1c;
    padding-top: 60px;
    padding-bottom: 40px;
  }
  
  #portfolio h1 {
    font: 18px/30px 'opensans-semibold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 48px;
    color: #e0c5cd;
  }
  
  #portfolio h5 {
    font: 24px/45px 'opensans-semibold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 10px;
    color: #be4a6d;
  }
  
  #portfolio-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the portfolio items */
    width: 100%;
  }
  
  .portfolio-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%; /* Adjust the width as needed */
    margin-bottom: 20px;
    background: #333;
    color: #fff;
  }
  
  .portfolio-item .item-wrap {
    display: contents;
  }
  
  .portfolio-item-meta {
    padding: 10px; /* Reduce padding */
    font-size: 14px; /* Adjust font size */
    line-height: 1.4; /* Adjust line height */
    width:88%;
  }
  
  .portfolio-item-meta h5 {
    margin-bottom: 8px; /* Reduce margin */
    font-size: 16px; /* Adjust font size */
  }
  
  .portfolio-item-meta p {
    margin-bottom: 6px; /* Reduce margin */
  }
  
  .portfolio-item-images {
    position: relative;
    align-self: center;
    width: 115%;
    height: 300px; /* Adjust the height as needed */
    overflow: hidden;
    translate: -12%;
  }
  
  .portfolio-item .item-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .portfolio-item .item-img.active {
    opacity: 1;
  }
  
  .hidden {
    display: none;
  }
  
  .overlayicon {
    position: absolute;
    right: 0;
    top: 0;
    width: 15%;
    height: 15%;
    padding: 1%;
    max-height: 15%;
    opacity: .65;
    -moz-opacity: .65;
    filter: alpha(opacity=.65);
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    z-index: 10; /* Ensure the overlay icon is on top */
  }
  
  .overlayicon:hover {
    opacity: .95;
    -moz-opacity: .95;
    filter: alpha(opacity=.95);
    -webkit-transition: opacity 0.3s ease-in-out;
  }
  
  /* Navigation buttons */
  .portfolio-item-images .prev,
  .portfolio-item-images .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: rgb(233, 213, 220);
    background-color: rgba(125, 19, 67, 0.8);
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    z-index: 11; /* Ensure the buttons are on top */
  }
  
  .portfolio-item-images .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .portfolio-item-images .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .portfolio-item-images .prev:hover,
  .portfolio-item-images .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  /* Filter buttons */
  .filter-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    translate: 20%;
    gap: 10px; /* Space the buttons farther apart */
    margin-bottom: 20px; /* Add some margin below the buttons */
    width: 70%;
  }
  
  .filter-buttons button {
    margin: 0 5px; /* Add horizontal margin to space the buttons */
    background-color: rgba(186, 53, 88, 0.8);
  }/* ...existing CSS... */

@media (max-width: 768px) {

.portfolio-item {
  display: table-column;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%; /* Adjust the width as needed */
  margin-bottom: 20px;
  background: #333;
  color: #fff;
}


.portfolio-item-meta {
  padding: 10px; /* Reduce padding */
  font-size: 14px; /* Adjust font size */
  line-height: 1.4; /* Adjust line height */
  width:100%;
}
.portfolio-item-images {
  position: relative;
  align-self: center;
  width: 115%;
  height: 300px; /* Adjust the height as needed */
  overflow: hidden;
  translate: -6.5%;
}
  .portfolio-item-images.mobile-layout {
    display: block;
    margin-top: 20px;
    position: relative;
    align-self: stretch;
    overflow: hidden;
  }  /* Filter buttons */
  .filter-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    translate: 0%;
    gap: 10px; /* Space the buttons farther apart */
    margin-bottom: 20px; /* Add some margin below the buttons */
    width: 100%;
  }
}